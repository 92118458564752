import React, { useRef, useEffect, Suspense, useState } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import ViewCube from "./ViewCube/ViewCube";
import OctagonPicker from "./Octagon/OctagonPicker";

function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/crank.glb");
  const { actions } = useAnimations(animations, group);

  console.log(props.color);

  return (
    <Center>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.crank.geometry}
          material={materials["Default OBJ"]}
          position={[0.00092448, 0.01886019, 0.02291852]}
          scale={0.00160124}
          material-color={props.color}
        />
      </group>
    </Center>
  );
}

useGLTF.preload("/crank.glb");

function Crank() {
  const [color, setColor] = useState("#ffffff");

  const objColor = (color) => {
    setColor(color);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#fff",
        overflow: "hidden",
      }}
    >
      <div className="controlsMain" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="controls">
          <OctagonPicker
            color={"#FFFFFF"}
            objColor={objColor}
            name={"branco"}
          />
          <OctagonPicker color={"#0066CC"} objColor={objColor} name={"azul"} />
          <OctagonPicker color={"#61C933"} objColor={objColor} name={"verde"} />
          <OctagonPicker
            color={"#FF9900"}
            objColor={objColor}
            name={"laranja"}
          />
          <OctagonPicker color={"#741B47"} objColor={objColor} name={"roxo"} />
        </div>
      </div>
      <div className="modelDiv">
        <Canvas>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} />
            <Model scale={1.5} color={color} />
            {/* <ViewCube /> */}
            <Environment files={"hdr2.hdr"} />
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <div className="octagonDiv">
          <OctagonPicker color={"#0066CC"} objColor={objColor} name={"azul"} />
          <OctagonPicker color={"#61C933"} objColor={objColor} name={"verde"} />
        </div>
        <div className="spacer25"></div>
        <div className="octagonDiv">
          <OctagonPicker
            color={"#FF9900"}
            objColor={objColor}
            name={"laranja"}
          />
          <OctagonPicker color={"#741B47"} objColor={objColor} name={"roxo"} />
        </div>
      </div>
    </div>
  );
}

export default Crank;
