import React, {
  useRef,
  useEffect,
  Suspense,
  useState,
  createContext,
  useContext,
} from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
  useContextBridge,
} from "@react-three/drei";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import Loader from "./Loader";
import ViewCube from "./ViewCube/ViewCube";
import { MeshBasicMaterial, TextureLoader, Vector3 } from "three";

const AppContext = createContext({});

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/puxador.glb");
  const { actions } = useAnimations(animations, group);

  const uploadedMat = useRef();
  const mesh = useRef();

  materials["Material #8black"].map.repeat.x = 15;
  materials["Material #8black"].map.repeat.y = 15;

  const { textureUrl } = useContext(AppContext);

  let mat2 = "";

  useEffect(() => {
    if (textureUrl) {
      const textureLoader = new TextureLoader();
      textureLoader.load(textureUrl, (t) => {
        uploadedMat.current.map = t;
        uploadedMat.current.needsUpdate = true;
        // const mate = new MeshBasicMaterial({ map: t });
        // mat2 = mate;
        uploadedMat.map.repeat.x = 50;
        uploadedMat.map.repeat.y = 50;
        // console.log(mate);
      });
      console.log("textura: ", textureUrl);
    }
  }, [textureUrl]);

  // mesh.rotation.set(new Vector3(0,0,Math.PI/2));
  // let teste = useLoader(TextureLoader, textureUrl);
  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="4FE5347712138B93BA7D97D3638CE1F56ABB4967___B8A4396250C5_e9d76d5"
            castShadow
            receiveShadow
            geometry={
              nodes[
                "4FE5347712138B93BA7D97D3638CE1F56ABB4967___B8A4396250C5_e9d76d5"
              ].geometry
            }
            material={materials["Material #27"]}
            position={[-0.49302882, -0.49312639, -1.24865985]}
            rotation={[Math.PI / 2, -3e-8, Math.PI]}
            scale={0.21442269}
          />
          <mesh
            name="14F76F0A1F308EC4BD72B942F56B7D5D452C0865||B8A4396250C5_f4a5316"
            castShadow
            receiveShadow
            geometry={
              nodes[
                "14F76F0A1F308EC4BD72B942F56B7D5D452C0865||B8A4396250C5_f4a5316"
              ].geometry
            }
            material={materials["Material #27"]}
            position={[-0.74649495, -0.22799481, -1.33065534]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            scale={0.21467705}
          />
          <group
            name="Line016"
            position={[-0.82187432, -0.0156914, -0.98086882]}
            rotation={[-Math.PI, 1.5e-7, -Math.PI]}
            scale={0.22872363}
          >
            <mesh
              name="Mesh012"
              castShadow
              receiveShadow
              geometry={nodes.Mesh012.geometry}
              material={materials["Material #57"]}
            />
            <mesh
              name="Mesh012_1"
              castShadow
              receiveShadow
              geometry={nodes.Mesh012_1.geometry}
              material={materials["Material #27"]}
            />
            <mesh
              ref={mesh}
              name="Mesh012_2"
              castShadow
              receiveShadow
              geometry={nodes.Mesh012_2.geometry}
              // ref={uploadedMat}
              // material={textureUrl ? useLoader(TextureLoader, textureUrl) : materials["Material #8black"]}
            >
              <meshStandardMaterial ref={uploadedMat} />
            </mesh>
          </group>
        </group>
      </group>
      {/* <mesh ref={mesh}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial ref={uploadedMat} />
      </mesh> */}
    </Center>
  );
}

useGLTF.preload("/puxador.glb");

const Model = () => {
  const ContextBridge = useContextBridge(AppContext);
  return (
    <Canvas>
      <ContextBridge>
        <Suspense fallback={<Loader />}>
          <OrbitControls rotateSpeed={0.7} maxDistance={30} />
          <ModelExplode />
          <ViewCube />
          <Environment files={"hdr2.hdr"} />
        </Suspense>
      </ContextBridge>
    </Canvas>
  );
};

const createImageUrl = (buffer, type) => {
  const blob = new Blob([buffer], { type });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;
};

function Janela() {
  const [textureUrl, setTextureUrl] = useState(null);
  const getTextureFile = async (e) => {
    const file = e.target.files[0];
    const { type } = file;
    const buffer = await file.arrayBuffer();
    const imageUrl = createImageUrl(buffer, type);
    setTextureUrl(imageUrl);
  };

  return (
    <AppContext.Provider value={{ textureUrl }}>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#fff",
          overflow: "hidden",
        }}
      >
        <div className="controlsMain">
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              background: "red",
            }}
          >
            <form>
              <input type="file" onChange={(e) => getTextureFile(e)} />
            </form>
          </div>
        </div>
        <div className="modelDiv">
          <Model />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default Janela;
