import React, { useRef, Suspense, useState } from "react";
import { useGLTF, Environment, OrbitControls, Center } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Loader from "./Loader";
import { Button } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/Janela.glb");

  console.log(props);

  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep11.geometry}
          material={materials["01 - Defaultfwe"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep12.geometry}
          material={materials["01 - Defaultfwe"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep13.geometry}
          material={materials["01 - Defaultfwe"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep14.geometry}
          material={materials["01 - Defaultfwe"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep21.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep22.geometry}
          material={materials["Empty material"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep23.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep24.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep25.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep41.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep42.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep5.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep43.geometry}
          material={materials["01 - Defaultre"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep6.geometry}
          material={materials["Empty material"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sweep44.geometry}
          material={materials["Empty material"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chamfer2.geometry}
          material={materials["01 - Defaulted"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chamfer1.geometry}
          material={materials["01 - Defaulted"]}
          position={[-0.5237722, 0.8400953, -0.10870443]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
    </Center>
  );
}

useGLTF.preload("/Janela.glb");

function Janela() {
  const controls = useRef();
  let [x, setX] = useState(55);
  let [y, setY] = useState(-10);
  let [z, setZ] = useState(10);

  const resetPosition = () => {
    setX = 55;
    setY = -10;
    setZ = 10;
    controls.current.reset();
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#fff",
        overflow: "hidden",
      }}
    >
      <div className="controlsMain">
        <div className="controls">
          <Button
            variant="contained"
            className="btnAnimation"
            onClick={resetPosition}
          >
            Reset
          </Button>
        </div>
      </div>
      <div className="modelDiv">
        <Canvas camera={{ position: [x, y, z], fov: 10 }}>
          <Suspense fallback={<Loader />}>
            <OrbitControls ref={controls} rotateSpeed={0.7} maxDistance={30} />

            <ModelExplode
              scale={4}
              // rotation={[x, y, z]}
            />

            <ViewCube />
            <Environment files={"hdr2.hdr"} />
          </Suspense>
        </Canvas>
      </div>
      {/* <div className="btnBottomDiv">
        <Button
          variant="contained"
          onClick={playSapatoNormal}
          disabled={sapatoNormal ? true : false}
          className="btnAnimation"
        >
          Sapato
        </Button>
      </div> */}
    </div>
  );
}

export default Janela;
