import { Center } from "@react-three/drei";
import React from "react";

const OctagonPicker = (props) => {
  return (
    <>
      <div
        className="octagon"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: `${props.color}`,
        }}
        onClick={() => props.objColor(props.color)}
      >
        <span style={{fontSize: "0.6rem"}}>{props.name}</span>
      </div>
      <div className="spacer25"></div>
    </>
  );
};

export default OctagonPicker;
