import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Modelo3 from "./components/Modelo3";
import Janela from "./components/Janela";
import Puxador from "./components/Puxador";
import Crank from "./components/Crank";
import ShoeFinal from "./components/ShoeSim/ShoeFinal";
import Pillow from "./components/Pillow";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Model3" component={Modelo3} />
        <Route exact path="/Janela" component={Janela} />
        <Route exact path="/Puxador" component={Puxador} />
        <Route exact path="/Crank" component={Crank} />
        <Route exact path="/Shoe" component={ShoeFinal} />
        <Route exact path="/Pillow" component={Pillow} />
      </Switch>
    </BrowserRouter>
  );
}
