import React, {
  useRef,
  useEffect,
  Suspense,
  useState,
  createContext,
  useContext,
} from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
  useContextBridge,
} from "@react-three/drei";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import Loader from "./Loader";
import ViewCube from "./ViewCube/ViewCube";
import {
  LinearMipMapLinearFilter,
  MeshBasicMaterial,
  NearestFilter,
  TextureLoader,
  Vector3,
} from "three";

const AppContext = createContext({});

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/uploads_files_2249051_kissen12.glb");

  const uploadedMat = useRef();
  const mesh = useRef();

  const { textureUrl } = useContext(AppContext);

  useEffect(() => {
    if (textureUrl) {
      const textureLoader = new TextureLoader();
      textureLoader.load(textureUrl, (t) => {
        uploadedMat.current.map = t;
        uploadedMat.current.needsUpdate = true;
        console.log(uploadedMat);
      });
    }
  }, [textureUrl]);



  return (
    <group {...props} dispose={null} >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials.SofaCloth}
        position={[1.27032149, -0.1699236, 0]}
        rotation={[0, 0, 0.22209963]}
        // material-color="#68B96A"
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        // material={materials.Textura}
        position={[1.27032149, -0.17140312, 0]}
        rotation={[0, 0, 0.22209963]}
      >
        <meshBasicMaterial ref={uploadedMat} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/uploads_files_2249051_kissen12.glb");

const Model = () => {
  const ContextBridge = useContextBridge(AppContext);
  return (
    <Canvas>
      <ContextBridge>
        <Suspense fallback={<Loader />}>
          <OrbitControls rotateSpeed={0.7} maxDistance={30} />
          <ModelExplode />
          <ViewCube />
          <Environment files={"hdr2.hdr"} />
        </Suspense>
      </ContextBridge>
    </Canvas>
  );
};

const createImageUrl = (buffer, type) => {
  const blob = new Blob([buffer], { type });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;
};

function Pillow() {
  const [textureUrl, setTextureUrl] = useState(null);
  const getTextureFile = async (e) => {
    const file = e.target.files[0];
    const { type } = file;
    const buffer = await file.arrayBuffer();
    const imageUrl = createImageUrl(buffer, type);
    setTextureUrl(imageUrl);
  };

  return (
    <AppContext.Provider value={{ textureUrl }}>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#fff",
          overflow: "hidden",
        }}
      >
        <div className="controlsMain">
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              background: "red",
            }}
          >
            <form>
              <input type="file" onChange={(e) => getTextureFile(e)} />
            </form>
          </div>
        </div>
        <div className="modelDiv">
          <Model />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default Pillow;
